import { Container, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Menu from '../components/Menu'
import Footer from '../components/Footer'

const NotFound = () => {
  return (
    <>
      <Menu />
      <Container fluid='md' className='mt-5'>
        <Row>
          <Col>
            <div className='card'>
              <div className='card-header'>404</div>
              <div className='card-body'>
                <h5 className='card-title'>Custom Error Page</h5>
                <p className='card-text'>
                  Edit Custom 404 error page <code>src/pages/NotFound.js</code>
                </p>
                <LinkContainer to='/'>
                  <button className='btn btn-primary'>Home Page</button>
                </LinkContainer>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className='fixed-bottom'>
        <Footer />
      </div>
    </>
  )
}

export default NotFound
