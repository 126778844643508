import { useState } from "react";
import mentor from "../assets/mentoringIndia.png";
import rodemap from "../assets/roadMap.png";
import { Button, Modal, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import RegisterModal from "../components/RegisterModal";
import Impact from "../components/Impact";
import Menu from '../components/Menu';
import Footer from "../components/Footer";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();

  const [logIn, setLogin] = useState(false),
    loginShow = () => setLogin(true),
    loginHide = () => setLogin(false);

  const [pwd, setPwd] = useState(''),
        [email, setEmail] = useState(''); 


  const ImgStyle = {
    width: "85%",
    display: "flex",
    margin: "auto",
    marginTop: "10px",
    background: "antiquewhite",
  };

  const signIn = async () => {
    try {
      if (email === '' || pwd === '') {
        return alert('All fields required!!');
      } else {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/paid/sign-in`, {
                      email,
                      pwd
                    });
        const result = res.data;
        // console.log(res);
        if (res.status === 200) {
          localStorage.setItem("jwttoken", result.token);
          localStorage.setItem("_id", result.user._id);
          history.push(`/stud_dashboard/${result.user._id}`);
        } else {
          console.log(res);
        }
      }
    } catch (error) {
      if (error.response.status=== 401) {
        // alert('Invalid Credentials!');
        toast.error('Invalid Credentials', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (error.response.status === 404) {
        // alert('User Doesn\'t Exists!');
        toast.warn('User Doesn\'t Exists!', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      else {
        console.log(error);
      }
    }
  }

  const btn = <Button variant="outline-secondary" className="px-4" style={{marginRight: "50px", marginLeft: "auto"}} onClick={loginShow}>
  Login
</Button>;

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal show={logIn} onHide={loginHide}>
        <Modal.Header>
          <Modal.Title>LogIn</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mt-2'>
              <Form.Label>
                Email Account
              </Form.Label>
              <Form.Control name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>
                Password
              </Form.Label>
              <Form.Control name="pwd" type="password" value={pwd} onChange={(e) => setPwd(e.target.value)} placeholder="Enter Password" />
            </Form.Group>
            <Form.Group className="text-center mt-4">
              <Button onClick={signIn} className="w-50" size="sm" variant='dark'>
                Sign In
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn-sm' variant="dark" onClick={loginHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Menu btn={btn} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <img src={mentor} alt="mentor" className="img-fluid" />
          </div>
          <div className="col-md-5 p-5 mt-2">
            
            <h2>Providing students with what they need the most</h2>
            <p>
              Mentoring India is a platform that gives students and young
              professionals wings and lets them believe in the power of their
              dreams. Relying on the passion of the students and backed by
              industry experts, we help you create the future that you want for
              yourself.
            </p>
            <div className="p-2">
              <Button
                variant="primary"
                size="lg"
                type="submit"
                onClick={handleShow}
                className="button-color w-100"
              >
                Join us, to get started
              </Button>
            </div>
          </div>
        </div>
        <div className="whyMentor">
          <h1>Why Mentoring India?</h1>
          <p style={{ width: "86%", marginTop: "30px" }}>
            Does growth inspire you? Does the confidence to ace in your field keep
            you going? With Mentoring India, you’re investing in yourself and the
            skills that you want to bring to life. From basic communication to
            exploring career options, Mentoring India has your back!
          </p>
          <p>
            Our dedicated and enthusiastic community strives towards a common
            goal: bringing out the best in you!
          </p>
        </div>
        <h1 className="text-center">How we works</h1>
        <img
          src={rodemap}
          alt="mentor"
          className="img-fluid mt-5"
          style={ImgStyle}
        />
        <h1 className="text-center mt-5">Our Impact</h1>
        <Impact className="mt-5" />
        <RegisterModal
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
          initial={true}
        />
      </div>
      <Footer />
    </>
  );
};

export default Home;
