import React, { useEffect, useState } from 'react';
import Sidebar from './SideBar';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import SVGComponent from './assets/s.svg';
import { Spinner, Row, Col } from 'react-bootstrap';

const Dashboard = () => {

  const history = useHistory();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [mentor, setMentor] = useState({});
  const [user, setUser] = useState({});

  const loadData = () => {
    try {
      if (localStorage.getItem("jwttoken") === null)
        return history.push('/');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
      }
      setLoading(true);
      axios.post(`${process.env.REACT_APP_BASE_URL}/paid/load-data/${params._id}`, {}, { headers })
        .then(function (res) {
          const result = res.data;
          // console.log(result);
          setUser(result);
          if (result.assignto === "0") {
            setMentor("Mentor not assigned!");
          }
          else {
            axios.post(`${process.env.REACT_APP_BASE_URL}/user/load-data/${result.assignto}`)
              .then(function (res) {
                console.log('Resdata',res.data);
                setMentor(res.data);
                console.log('mentor', mentor);
              })
              .catch(function (err) {
                console.log(err);
              }
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        }
      );
      
      setLoading(false);

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const style={
    boxShadow: "2px 0px 1px black inset"
  }

  const content = 
  
  <main className='container'>
  
    {
      loading?
        <div className='text-center mt-5'>
          <Spinner style={{ width: '5rem', height: '5rem' }} animation="border" />
        </div>:
        <>
          <Row>
            <Col>
              <h1>{user.name}</h1>
            </Col>
          </Row>

          <Row className='mt-3'>

            <Col sm={8}>
              <h2 className='border-bottom d-inline'>Student Details</h2>
              <br />
              <br />
              <h6>Class: {user.classes}</h6>
              <h6>Email: {user.email}</h6>
              <h6>Phone No.: {user.phn}</h6>
              <h6>Address: {user.city}, {user.state}</h6>
              <h6>Course Undertaken: 7 Day Plan</h6>
            </Col>

            <Col style={style} sm={4} className='pt-1'>
              <h2 className='border-bottom d-inline'>Mentor Details</h2>
              <br />
              <br />
              {
                user.assignto === "0"?
                <h4>No Mentor Assigned</h4>:
                <>
                  <h4>{mentor.name}</h4>
                  <h6>Email-Id: {mentor.email}</h6>
                </>
              }
            </Col>

          </Row>

          <Row className='mt-4'>
            <img src={SVGComponent} style={{height: "280px", width: "auto"}} className='d-block mx-auto' />
          </Row>
        </>
    }
  
  </main>;

  return (
    <>
      <Sidebar children={content} />
    </>
  );
}

export default Dashboard;