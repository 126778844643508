import 'react-bootstrap-drawer/lib/style.css';
import React, { useState } from 'react';
import logo from '../assets/mentoringLogo.png';
import { BiLogOut } from "react-icons/bi";
// import { FiLogOut } from "react-icons/fi";
import {
	Col,
	Collapse,
	Container,
	Row,
} from 'react-bootstrap';
import { Drawer, } from 'react-bootstrap-drawer';

const ApplicationDrawer = (props) => {
	const [open, setOpen] = useState(false);

	const handleToggle = () => setOpen(!open);

	return (
		<Drawer { ...props }>
			<Drawer.Toggle onClick={ handleToggle } />

			<Collapse in={ open }>
				<Drawer.Overflow>
					<Drawer.ToC className='text-center align-content-center'>
						<Drawer.Header href="/stud_dashboard" className='mb-5'>
							<a href="#!" className="logo-wrapper waves-effect">
								<img alt="Mentor India Logo" className="img-fluid" src={ logo } />
							</a>
						</Drawer.Header>

						<Drawer.Nav>
							<Drawer.Item href="#">
								<div className='lead'>
									
									Dashboard
								</div>
							</Drawer.Item>
						</Drawer.Nav>

						{/* <Drawer.Nav>
							<Drawer.Item href="#">
								<div className='lead'>
									
									Something
								</div>
							</Drawer.Item>
						</Drawer.Nav>

						<Drawer.Nav>
							<Drawer.Item href="#">
								<div className='lead'>
									
									Something
								</div>
							</Drawer.Item>
						</Drawer.Nav>

						<Drawer.Nav>
							<Drawer.Item href="#">
								<div className='lead'>
									
									Something
								</div>
							</Drawer.Item>
						</Drawer.Nav>

						<Drawer.Nav>
							<Drawer.Item href="#">
								<div className='lead'>
									
									Something
								</div>
							</Drawer.Item>
						</Drawer.Nav> */}

						<hr className='w-75 mx-auto' />

						<Drawer.Nav>
							<Drawer.Item href="/">
								<div className='lead bg-light'>
									<BiLogOut className='mx-2' />
									Logout
								</div>
							</Drawer.Item>
						</Drawer.Nav>
					</Drawer.ToC>
				</Drawer.Overflow>
			</Collapse>
		</Drawer>
	);
};

const Sidebar = (props) => {
	return (
		<Container fluid>
			<Row className="flex-xl-nowrap">
				<Col style={ { height: "100vh" } } as={ ApplicationDrawer } xs={ 12 } md={ 3 } lg={ 2 } />
				<Col xs={ 12 } md={ 9 } lg={ 10 } className='py-4 px-3'>{ props.children }</Col>
			</Row>
		</Container>
	);
};

export default Sidebar;