import Question from "../components/Question";
// import { questions } from "./Data";
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import Menu from '../components/Menu'
import Footer from '../components/Footer'

const Questions = () => {
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const allData = location.state;


  const loadData = () => {
    // try {
    axios.get(`${process.env.REACT_APP_BASE_URL}/qa/view-questions`).then((res) => {
      // axios.get(`http://localhost:5500/qa/view-questions`).then((res) => {
      // console.log(res.data);

      setQuestions(res.data);
    }).catch((err) => { console.log(err) });
    // console.log(questions);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  useEffect(() => {
    loadData();
    // console.log('Called', questions);
    // console.log(allData, 'alldata');
  }, []);

  // console.log(questions);

  return (
    <>
      <Menu />
      <div className="container-fluid" style={{minHeight: "70vh"}}>
        {questions.length > 0 ? <Question questions={questions} allData={allData} /> : <Spinner animation="border" role="status" />}
      </div>
      <Footer />
    </>
  );
};

export default Questions;
