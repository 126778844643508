import { Route, BrowserRouter as Router, Switch } from "react-router-dom";


// pages
import Home from "./pages/Home";
import About from "./pages/About";
import Questions from "./pages/Questions";
import NotFound from "./pages/NotFound";
import Info from "./pages/Info";
import Subscription from "./pages/Subscription";
import School from "./pages/School";
import GetMyPlan from "./pages/GetMyPlan";
import Payment from "./pages/Payment";
import Dashboard from "./dashboard/Dashboard";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={About} />
        <Route path="/info" component={Info} />
        <Route path="/questions" component={Questions} />
        <Route path="/subscription" component={Subscription} />
        <Route path="/school" component={School} />
        <Route path="/getmyplan" component={GetMyPlan} />
        <Route path="/payment" component={Payment} />
        <Route path="/stud_dashboard/:_id" component={Dashboard} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
