import React from "react";

function SubscriptionComp() {
  return (
    <div>
      <div class="wrapper">
        <div class="card1">
          <h1>
            <span class="enclosed">Fire</span>🔥
          </h1>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionComp;
